import React, {Component} from 'react';
import {
  Navbar,
  NavbarBrand,
  Button
} from 'reactstrap'
import './styles/App.css';
import Maze from './modules/Maze/Maze'
import LocalizedStrings from 'react-localization';
import ContactForm from "./modules/ContactForm/ContactForm";
import UnderHeader from "./modules/UnderHeader/UnderHeader"
import Torlin from "./modules/Torlin/Torlin";
import Products from "./modules/Products/Products";
import References from "./modules/References/References";
import {scroller as scroll} from 'react-scroll';

const logo_image = require('./images/headsoft_logo.svg');
const logo_image2 = require('./images/headsoft_logo_white.svg');

class App extends Component {

  constructor(props) {
    super(props);

    this.max_maze_height = 812;

    this.state = {
      logo_image: logo_image,
      mazeHeight: this.getMazeHeight(),
      bodyWidth: 1000,
      isOpen: false,
      navbar_block_class: 'light',
      toggler_styles: {background: 'inherited'},
      mottoText: 'Víme, kudy vede cesta'
    };

    this.strings = new LocalizedStrings({
      en: {
        services: "Služby",
        references: "Reference",
        contact: "Kontakt",
        mainMoto: 'Hledáme cesty z problémů',
        motoDescription1: 'Specializujeme se na vývoj softwarových řešení na míru, která dokáží efektivně',
        motoDescription2: 'vyřešit procesní, technologické nebo administrativní otázky.',
        contactUs: 'Kontaktujte nás'
      },
      cs: {
        services: "Služby",
        references: "Reference",
        contact: "Kontakt",
        mainMoto: 'Nehledejte cestu - my ji známe',
        motoDescription1: 'Specializujeme se na vývoj softwarových řešení na míru, která dokáží efektivně',
        motoDescription2: 'vyřešit procesní, technologické nebo administrativní otázky.',
        contactUs: 'Kontaktujte nás'
      }
    });

    this.toggle = this.toggle.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.clickMenu = this.clickMenu.bind(this);
  }

  handleScroll() {
    let old_class = this.state.navbar_block_class;
    let new_class = '';
    if (window.scrollY > (this.state.mazeHeight - Math.max(100, window.innerWidth / 10))) {
      new_class = 'dark';
    } else {
      new_class = 'light';
    }
    if (old_class !== new_class) {
      this.setState({navbar_block_class: new_class})
    }

    if (window.innerWidth > 575) {
      if (window.scrollY > 40) {
        this.refs.navbar_block.style.position = 'fixed';
        this.refs.navbar_block.style.top = 0;
      } else {
        this.refs.navbar_block.style.position = 'absolute';
        this.refs.navbar_block.style.top = '40px';
      }
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  toggle() {
    let status = !this.state.isOpen;

    if (status === true) {
      this.setState({logo_image: logo_image2, toggler_styles: {background: 'white'}, isOpen: status});
      this.refs.navbar_items.style.display = 'block';
      this.refs.navbar_cross_toggle.style.display = 'block';
    } else {
      this.setState({logo_image: logo_image, toggler_styles: {background: null}, isOpen: status});
      this.refs.navbar_items.style.display = 'none';
      this.refs.navbar_cross_toggle.style.display = 'none';
    }
  }

  getMazeHeight() {
    return this.max_maze_height;
  }

  clickMenu(elm) {
    if (this.state.isOpen) {
      this.toggle();
    }
    scroll.scrollTo(elm.target.className, {
      duration: 500,
      delay: 50,
      smooth: true,
      offset: 0
    });

    return true;
  }

  render() {
    return (
      <div className="App">
        <div id={'top_background'} />
        <div id={'web_header'} style={{height: this.state.mazeHeight}}>
          <div id={'maze'}>
          <Maze ref={'maze'} mazeHeight={this.state.mazeHeight} updateMazeHeight={(height) => {
            this.setState({mazeHeight: height})
          }}/>
          </div>
          <div className={"MainContainer"} style={{position: 'relative', zIndex: 2000}}>
            <div id={'navbar_block'} ref={'navbar_block'}>
              <Navbar expand={'sm'}>
                <NavbarBrand href={'/'}>
                  <div className={'d-none d-md-block'}/>
                  <div id={'headsoft_logo'}><img alt={'Headsoft'} src={this.state.logo_image}/></div>
                </NavbarBrand>
                <button ref={'toggler_button'} className={'navbar-toggler'} style={this.state.toggler_styles}
                        onClick={this.toggle}><span className={'rect1'}/><span className={'rect2'}/><span className={'rect3'}/><div ref={'navbar_cross_toggle'} className={'close'}/> </button>

                <ul ref={'navbar_items'} className={'navbar-nav'}>
                  <li className={'nav-item'}><a className={'services'} onClick={this.clickMenu} href={null}>{this.strings.services}</a></li>
                  <li className={'nav-item'}><a className={'references'} onClick={this.clickMenu} href={null}>{this.strings.references}</a></li>
                  <li className={'nav-item'}><a className={'contact'} onClick={this.clickMenu} href={null}>{this.strings.contact}</a></li>
                </ul>

              </Navbar>
            </div>
          </div>
          <div id={'headsoft_moto_text'}>
            <div>
                <h1>{this.state.mottoText}</h1>
                  <p>
                    {this.strings.motoDescription1} <br/>
                    {this.strings.motoDescription2}
                  </p>
                  <Button onClick={()=>{scroll.scrollTo('contact', {
                    duration: 500,
                    delay: 50,
                    smooth: true,
                    offset: 0
                  });}}>{this.strings.contactUs}</Button>
            </div>
          </div>
        </div>
        <UnderHeader/>
        <span id={'services'}/>
        <Torlin/>
        <Products/>
        <span id={'references'}/>
        <References/>
        <ContactForm/>
      </div>
    );
  }

}

export default App;
