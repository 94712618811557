import React, {Component} from 'react';

export default class Maze extends Component {

  constructor(props) {
    super(props);

    this.pixelRatio = window.devicePixelRatio;
    this.mazeX = 1;
    this.mazeY = 1;
    this.cellSize = 17  ;
    this.lineRadiosRatio = 30;
    this.gameX = this.cellSize;
    this.gameY = this.cellSize;
    this.posArc1 = 1;
    this.posArc2 = 1;
    this.activated = false;
    this.mazePath = [[1, 1]];
    this.bouncer_width = this.bouncer_height = 100;
    this.current_width = window.innerWidth;
    this.current_height = window.innerHeight;

    this.state = {
      divStyle: {
        zIndex: 1000,
        left: 0,
        position: 'absolute',
        maze: [],
      },
      mazeHeight: this.props.mazeHeight,
      pixelRatio: this.pixelRatio
    };

    this.updateMaze = this.updateMaze.bind(this);
  }


  render() {
    return (
      <div style={this.state.divStyle} ref={'maze_div'}
           // onMouseMove={this.mouseMove.bind(this)}
           // onMouseDown={this.mouseDown.bind(this)}
           // onMouseUp={this.mouseUp.bind(this)}
      >
        <canvas ref={'maze_round'} width={this.bouncer_width} height={this.bouncer_height}
                style={{position: 'absolute', top: 0, zIndex: 2600, width: this.bouncer_width/this.state.pixelRatio, height: this.bouncer_height/this.state.pixelRatio}}/>
        <canvas ref={'maze_path'} style={{position: 'absolute', top: 0, zIndex: 998}}/>
        <canvas ref={'maze_canvas_borders'} style={{position: 'absolute', top: 0, zIndex: 997}}/>
        <canvas ref={'maze_canvas_background'}/>
      </div>
    );
  }

  componentDidMount() {
    this.calculateMazeHeight();
    this.updateMaze();
    this.drawBouncer(this.cellSize, this.lineRadiosRatio);
    window.addEventListener('orientationchange', this.orientationChanged.bind(this));
    window.addEventListener('resize', this.updateMaze);
    this.startDemo();
  }


  orientationChanged() {
    this.refs.maze_path.style.width = 10 + "px";
    this.refs.maze_canvas_borders.style.width = 10 + "px";
    this.refs.maze_canvas_background.style.width = 10 + "px";
    this.setState({pixelRatio: window.devicePixelRatio});
    this.pixelRatio = window.devicePixelRatio;

    setTimeout(()=>{this.updateMaze()},200);
  }

  startDemo() {
    if (this.demo) {
      return;
    }
    this.demoDirection = '';
    this.lastDirection = '';

    this.demo = setInterval(() => {
      let up = (this.state.maze[this.mazeY - 1][this.mazeX] === 0);
      let down = (this.state.maze[this.mazeY + 1][this.mazeX] === 0);
      let left = (this.state.maze[this.mazeY][this.mazeX - 1] === 0);
      let right = (this.state.maze[this.mazeY][this.mazeX + 1] === 0);

      let possibles = [];

      if (up) possibles.push('U');
      if (down) possibles.push('D');
      if (left) possibles.push('L');
      if (right) possibles.push('R');

      this.activated = true;
      this.drawPath();
      this.activated = false;

      if ((this.gameY % this.cellSize) === 0 && (this.gameX % this.cellSize) === 0) {
        this.mazePath.push([this.gameX / this.cellSize, this.gameY / this.cellSize]);
        this.lastDirection = this.demoDirection;
        if ((this.demoDirection === 'U' && !up) || possibles.length >= 2) this.demoDirection = '';
        if ((this.demoDirection === 'D' && !down) || possibles.length >= 2) this.demoDirection = '';
        if ((this.demoDirection === 'L' && !left) || possibles.length >= 2) this.demoDirection = '';
        if ((this.demoDirection === 'R' && !right) || possibles.length >= 2) this.demoDirection = '';
      }

      if (this.demoDirection === '') {
        if (possibles.length > 1) {
          let findingIndex = '';
          if (this.lastDirection === 'L') {
            findingIndex = 'R';
          } else if (this.lastDirection === 'R') {
            findingIndex = 'L';
          } else if (this.lastDirection === 'U') {
            findingIndex = 'D';
          } else if (this.lastDirection === 'D') {
            findingIndex = 'U';
          }
          if (possibles.indexOf(findingIndex) !== -1) {
            possibles.splice(possibles.indexOf(findingIndex), 1);
          }
        }

        let calculatedIndex = (Math.floor((Math.random() * possibles.length)));
        this.demoDirection = possibles[calculatedIndex];
      }

      switch (this.demoDirection) {
        case 'D':
          this.gameY +=1;
          break;
        case 'R':
          this.gameX +=1;
          break;
        case 'L':
          this.gameX -=1;
          break;
        case 'U':
          this.gameY -=1;
          break;
        default:
      }

      this.mazeY = Math.floor(this.gameY / this.cellSize);
      this.mazeX = Math.floor(this.gameX / this.cellSize);

    }, 36)
  }

  calculateMazeHeight() {
    let currentHeight = this.state.mazeHeight;
    let width = this.getWindowWidth();
    let newHeight = 0;

    if (width<765) {
      newHeight = 480;
    } else {
      newHeight = 812;
    }

    if (currentHeight !== newHeight) {
      this.props.updateMazeHeight(newHeight);
      this.setState({mazeHeight: newHeight}, this.updateMaze);
    }
  }

  updateMaze(event) {
    this.calculateMazeHeight();
    if (event && event.type === 'resize') {
      if (window.innerWidth === this.current_width)
        // in mobile there is often change height. So check width only.
        return;
    }
    this.mazePath = [];
    this.gameY = this.cellSize;
    this.gameX = this.cellSize;
    this.mazeY = 1;
    this.mazeX = 1;
    this.lastDirection = '';
    this.demoDirection = '';
    this.calculateMazeHeight();
    this.updateBackground(this.lineRadiosRatio);
    this.drawMaze(this.cellSize, this.lineRadiosRatio);
  }


  drawPath() {
    if (this.activated === false) return;
    let canvas = this.refs.maze_path;

    let ctx = canvas.getContext('2d');
    // ctx.scale(this.pixelRatio, this.pixelRatio);
    // ctx.clearRect(0,0,5000,5000);
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#05628f';

    ctx.beginPath();

    if (this.mazePath.length) {
      ctx.moveTo(Math.round(this.mazePath[this.mazePath.length-1][0]*this.cellSize*this.pixelRatio), Math.round(this.mazePath[this.mazePath.length-1][1]*this.cellSize*this.pixelRatio));
      ctx.lineTo(Math.round(this.gameX*this.pixelRatio), Math.round(this.gameY*this.pixelRatio));
    }

    ctx.stroke();
    ctx.closePath();

  };


  drawBouncer(cellSize, lineRadiosRatio) {
    let canvas = this.refs.maze_round;
    let radius = 5*this.pixelRatio;
    let ctx = canvas.getContext('2d');

    canvas.style.left = (this.gameX - (this.bouncer_width/2)/this.pixelRatio) + "px";
    canvas.style.top = (this.gameY - (this.bouncer_height/2)/this.pixelRatio) + "px";
    ctx.clearRect(0,0,this.bouncer_width,this.bouncer_height);
    ctx.beginPath();
    let gradient = ctx.createLinearGradient(this.bouncer_width/2 + radius, this.bouncer_height/2, this.bouncer_width/2 + radius, this.bouncer_height/2 + radius);
    gradient.addColorStop(0, '#43d0ec');
    gradient.addColorStop(1, '#2178c6');
    ctx.arc(this.bouncer_width/2, this.bouncer_height/2, radius, 0, 2 * Math.PI);
    ctx.fillStyle = gradient;
    ctx.fill();
    ctx.closePath();

    if (this.posArc1 <= 30) {
      ctx.beginPath();
      ctx.arc(this.bouncer_width/2, this.bouncer_height/2, Math.round(radius + this.posArc1), 0, 2 * Math.PI);
      let step = 1 / (30 - 2);
      let alpha1 = 1 - (this.posArc1 * step);
      ctx.strokeStyle = 'rgba(62,212,245,' + alpha1 + ')';
      ctx.stroke();
      ctx.closePath();
    }
    this.posArc1+=0.5;
    if (this.posArc1 === 100) this.posArc1 = 2;

    if (this.posArc2 <= 30) {
      ctx.beginPath();
      ctx.arc(this.bouncer_width/2, this.bouncer_height/2, Math.round(radius + this.posArc2), 0, 2 * Math.PI);
      let step = 1 / (30 - 2);
      let alpha1 = 1 - (this.posArc2 * step);
      ctx.strokeStyle = 'rgba(62,212,245,' + alpha1 + ')';
      ctx.stroke();
      ctx.closePath();
    }
    this.posArc2 += 0.7;
    if (this.posArc2 >= 100) this.posArc2 = 2;
    window.requestAnimationFrame(()=>{this.drawBouncer(cellSize, lineRadiosRatio);});
  }


  drawMaze(cellSize, lineRadiosRatio) {
    let canvas = this.refs.maze_canvas_borders;
    let canvas_path = this.refs.maze_path;

    let middleHeight = this.state.mazeHeight;
    let width = this.getWindowWidth();
    let height = middleHeight + (width / lineRadiosRatio) - this.lightBlueLineWidth;

    canvas.style.width = canvas_path.style.width = width + "px";
    canvas.style.height = canvas_path.style.height = height + "px";

    canvas.width = canvas_path.width = width * this.pixelRatio;
    canvas.height = canvas_path.height = height * this.pixelRatio;

    let mazeWidth = Math.floor(width / cellSize);
    let mazeHeight = Math.floor(height / cellSize);
    // mazeWidth = 61;
    // mazeHeight = 15;
    if (!(mazeHeight % 2)) {
      mazeHeight += 1;
    }
    if (!(mazeWidth % 2)) {
      mazeWidth += 1;
    }
    let mazeData = this.newMazeGenerator(mazeWidth, mazeHeight, cellSize, lineRadiosRatio / 2);

    this.setState({maze: mazeData});

    let ctx = canvas.getContext('2d');
    ctx.scale(this.pixelRatio, this.pixelRatio);
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#262626';

    for (let i = 0; i < mazeHeight; i++) {
      for (let j = 0; j < mazeWidth; j++) {
        if (mazeData[i][j] === 0) {
          if (mazeData[i][j - 1] === 1) {
            ctx.beginPath();
            ctx.moveTo(j * cellSize - cellSize / 2, i * cellSize - cellSize / 2);
            ctx.lineTo(j * cellSize - cellSize / 2, i * cellSize + cellSize / 2);
            ctx.stroke();
            ctx.closePath();
          }
          if (mazeData[i][j + 1] === 1) {
            ctx.beginPath();
            ctx.moveTo(j * cellSize + cellSize / 2, i * cellSize - cellSize / 2);
            ctx.lineTo(j * cellSize + cellSize / 2, i * cellSize + cellSize / 2);
            ctx.stroke();
            ctx.closePath();
          }
          if (mazeData[i + 1] !== undefined) {
            if (mazeData[i + 1][j] === 1) {
              ctx.beginPath();
              ctx.moveTo(j * cellSize - cellSize / 2, i * cellSize + cellSize / 2);
              ctx.lineTo(j * cellSize + cellSize / 2, i * cellSize + cellSize / 2);
              ctx.stroke();
              ctx.closePath();
            }
            if (mazeData[i - 1][j] === 1) {
              ctx.beginPath();
              ctx.moveTo(j * cellSize - cellSize / 2, i * cellSize - cellSize / 2);
              ctx.lineTo(j * cellSize + cellSize / 2, i * cellSize - cellSize / 2);
              ctx.stroke();
              ctx.closePath();
            }
          }
        }
      }
    }
  }

  updateBackground(lineRadiosRatio) {
    const darkBlueLineWidth = 4;
    const lightBlueLineWidth = 25;
    const lightBlueTriangleWidth = 70;
    const lightBlueTriangleHeight = 40;
    const darkTriangleHeight = 25;
    const darkTriangleWidth = 70;

    this.lightBlueLineWidth = lightBlueLineWidth;

    let canvas = this.refs.maze_canvas_background;
    let middleHeight = this.state.mazeHeight;
    let width = this.getWindowWidth();
    let height = middleHeight + (width / lineRadiosRatio);
    let triangleHeight = middleHeight + lightBlueTriangleHeight + lightBlueLineWidth / 2;

    let actualHeight = Math.max(height, triangleHeight);
    canvas.style.width = width + "px";
    canvas.style.height = actualHeight + "px";
    canvas.width = width * this.pixelRatio;
    canvas.height = actualHeight * this.pixelRatio;


    let leftBottom = height;
    let rightBottom = middleHeight - (width / lineRadiosRatio);
    let ctx = canvas.getContext('2d');
    ctx.scale(this.pixelRatio, this.pixelRatio);

    /** Dark Background */
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(0, (leftBottom - lightBlueLineWidth - darkBlueLineWidth));
    ctx.lineTo(width, (rightBottom - lightBlueLineWidth - darkBlueLineWidth));
    ctx.lineTo(width, 0);
    ctx.fillStyle = '#1f1f1f';
    ctx.fill();
    ctx.closePath();

    /** LightBottomLine **/
    /*let lightLine1X = width / 2 - (lightBlueTriangleWidth / 2);
    let lightLine2X = width / 2;
    let lightLine3X = width / 2 + (lightBlueTriangleWidth / 2);
    let lightLine1Y = (middleHeight - (lightBlueLineWidth / 2)) + (lightBlueTriangleWidth / lineRadiosRatio / 2);
    let lightLine2Y = (middleHeight - (lightBlueLineWidth / 2)) + lightBlueTriangleHeight;
    let lightLine3Y = (middleHeight - (lightBlueLineWidth / 2)) - (lightBlueTriangleWidth / lineRadiosRatio);
    ctx.beginPath();
    ctx.moveTo(0, (leftBottom - (lightBlueLineWidth / 2)));
    ctx.lineTo(lightLine1X, lightLine1Y);
    ctx.lineTo(lightLine2X, lightLine2Y);
    ctx.lineTo(lightLine3X, lightLine3Y);
    ctx.lineTo(width, (rightBottom - (lightBlueLineWidth / 2)));
    ctx.lineWidth = lightBlueLineWidth;
    ctx.strokeStyle = 'rgba(27,151,209,0.1)';
    ctx.stroke();
    ctx.closePath();
    */
    /** darkBottomLine */
    /*ctx.beginPath();
    ctx.moveTo(0, (leftBottom - (lightBlueLineWidth + darkBlueLineWidth / 2)));
    ctx.lineTo(width, (rightBottom - (lightBlueLineWidth + darkBlueLineWidth / 2)));
    ctx.lineWidth = darkBlueLineWidth;
    ctx.strokeStyle = 'rgba(132,185,211)';
    ctx.stroke();
    ctx.closePath();
    */
    ctx.beginPath();
    ctx.moveTo(width / 2 - darkTriangleWidth / 2 - 7, middleHeight - lightBlueLineWidth - darkBlueLineWidth - 5);
    ctx.lineTo((width / 2 + darkTriangleWidth / 2), (middleHeight - lightBlueLineWidth - darkBlueLineWidth - 5));
    ctx.lineTo(width / 2 + 2, middleHeight + darkTriangleHeight - lightBlueLineWidth - darkBlueLineWidth - 5);
    ctx.lineTo(width / 2 - 2, middleHeight + darkTriangleHeight - lightBlueLineWidth - darkBlueLineWidth - 5);
    ctx.fillStyle = '#1f1f1f';
    ctx.fill();
    ctx.closePath();
  }


  newMazeGenerator(mazeWidth, mazeHeight, cellSize, gradientRatio) {
    let moves = [];
    let maze = [];
    for (let i = 0; i < mazeHeight; i++) {
      maze[i] = [];
      for (let j = 0; j < mazeWidth; j++) {
        maze[i][j] = 1;
      }
    }
    let posY = 1;
    let posX = 1;
    maze[posY][posX] = 0;
    moves.push(posX + posX * mazeWidth);

    let loop_on = true;
    while (loop_on) {
      if (moves.length) {
        let possibleDirections = "";
        if (posY + 2 < this.getMazeMaxY(posX, mazeHeight, cellSize, gradientRatio) && maze[posY + 2][posX] === 1) {
          possibleDirections += "S";
        }
        if (posY - 2 > 0 && maze[posY - 2][posX] === 1) {
          possibleDirections += "N";
        }
        if (posX - 2 > 0 && maze[posY][posX - 2] === 1) {
          possibleDirections += "W";
        }
        if (posX + 2 < this.getMazeMaxX(posY + 2, mazeHeight, mazeWidth, cellSize, gradientRatio) && maze[posY][posX + 2] === 1) {
          possibleDirections += "E";
        }
        if (possibleDirections) {
          let move = Math.floor(Math.random() * possibleDirections.length - 1) + 1;
          switch (possibleDirections[move]) {
            case "N":
              maze[posY - 2][posX] = 0;
              maze[posY - 1][posX] = 0;
              posY -= 2;
              break;
            case "S":
              maze[posY + 2][posX] = 0;
              maze[posY + 1][posX] = 0;
              posY += 2;
              break;
            case "W":
              maze[posY][posX - 2] = 0;
              maze[posY][posX - 1] = 0;
              posX -= 2;
              break;
            case "E":
              maze[posY][posX + 2] = 0;
              maze[posY][posX + 1] = 0;
              posX += 2;
              break;
            default:
          }
          moves.push(posX + posY * mazeWidth);
        }
        else {
          let back = moves.pop();
          posY = Math.floor(back / mazeWidth);
          posX = back % mazeWidth;
        }
      } else {
        loop_on = false;
      }
    }
    return maze;
  }


  getMazeMaxY(posX, mazeHeight, cellSize, gradientRatio) {
    if (posX === 1) return mazeHeight;
    let forward = 1 + Math.ceil((((posX) * cellSize) / gradientRatio) / cellSize);
    return mazeHeight - forward;
  }

  getMazeMaxX(relX, maxX, maxY, size, ratio) {
    let leftMaxY = maxX * size;
    let rightMaxY = leftMaxY - maxY * (size / ratio);
    let need = relX * size;
    if (need < rightMaxY) return maxY;
    let missing = need - rightMaxY;
    let result = Math.ceil(missing / size) * ratio;
    return maxY - result;
  }


  getWindowWidth() {
    return Math.min(window.innerWidth, document.body.clientWidth);
  }
}