import React, {Component} from 'react';

const logo_opavanet = require('../../images/logos/opava_net_logo.png');


export default class UnderHeader extends Component {
  render() {
    return (<div id={'under_header'}>
      <div className={'inside_under_header'}>
        <div className={'first_block'}>
          <div className={'divider_first'}/>
          <p className={'info_text'}>Věříme, že každý technologický problém má své efektivní řešení na míru, které vám
            rádi pomůžeme najít a zrealizovat. Pod značkou <strong>TORLIN</strong> ale také nabízíme špičkový dohledový
            systém, který je obohacen o možnost podrobné analýzy pohybu na sledovaném prostoru.</p>
        </div>
      </div>
      <div className={'opavaNet'}>
        <a href={'https://www.opavanet.cz/'} title={'Opava Net'}><img width={197} src={logo_opavanet} alt={'Opava Net'}/></a>
        <div className={'thanks_text'}>
          <h5>Děkujeme společnosti OpavaNet</h5>
          <p className={'thanks_content'}>
            Děkujeme za vstříncný krok v době nouzového stavu během pandemie
          </p>
        </div>
      </div>
      <div className={'labyrint'}>
        <div className={'labyrint_inside'}>
          <p className={'labyrint_header'}>Baví nás hledat správná řešení</p>
          <p className={'labyrint_text'}>Cílem společnosti HEADSOFT je dosáhnout Vaší maximální spokojenosti tím, že
            důkladně zanalyzujeme předmětné prostředí a navrhneme nejvhodnější možné řešení.</p>
        </div>
      </div>
    </div>)
  }
}