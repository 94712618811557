import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Container} from 'reactstrap'
import './styles/index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/js/bootstrap.min.js'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faMapMarkerAlt, faPhone, faEnvelope, faAt} from '@fortawesome/free-solid-svg-icons'
import {faHeart} from '@fortawesome/free-regular-svg-icons'

library.add(faMapMarkerAlt, faHeart, faPhone, faEnvelope, faAt);

class Root extends Component {
  render() {
    return (
        <div id={'body'}>
          <App/>
      </div>
    );
  }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
registerServiceWorker();
