import React, {Component} from 'react';
import {Button, Col, Container, Input, Row} from "reactstrap";
import LocalizedStrings from 'react-localization';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {scroller as scroll} from 'react-scroll';
import {Fade} from "react-reveal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class ContactForm extends Component {

  constructor() {
    super();

    this.strings = new LocalizedStrings({
      en: {
        address: "Adresa",
        writeUs: "Napiště nám",
        emailName: 'Jméno',
        emailEmail: 'E-mailová adresa',
        emailSubject: 'Předmět',
        emailMessage: 'Jak vám můžeme pomoci?',
        sendEmail: 'Odeslat',
      },
      cs: {
        address: "Adresa",
        writeUs: "Napiště nám",
        emailName: 'Jméno',
        emailEmail: 'E-mailová adresa',
        emailSubject: 'Předmět',
        emailMessage: 'Jak vám můžeme pomoci?',
        sendEmail: 'Odeslat',
      }
    });

    this.state = {
      buttonShow: false,
      nameOK: false,
      emailOK: false,
      textOK: false,
      subjectOK: false,
      disableSend: true,
      nameValue: '',
      emailValue: '',
      subjectValue: '',
      messageValue: '',
    };
    this.fadeOutTimeout = null;
    this.sendButton = React.createRef();
    this.scrollToForm = this.scrollToForm.bind(this);
    this.focusOut = this.focusOut.bind(this);
    this.sendEmail = this.sendEmail.bind(this);

  }

  focusOut() {
    this.fadeOutTimeout = setTimeout(() => {
      this.setState({buttonShow: false});
    }, 100);
  }


  scrollToForm() {
    clearInterval(this.fadeOutTimeout);
    scroll.scrollTo("MessageName", {
      duration: 500,
      delay: 50,
      smooth: true,
      offset: -75
    });
    this.setState({buttonShow: true});
  }

  onChangeName(elm) {
    this.setState({nameValue: elm.target.value, nameOK: elm.target.value.length >= 4}, this.checkButton);
  }

  onChangeText(elm) {
    this.setState({messageValue: elm.target.value, textOK: elm.target.value.length >= 20}, this.checkButton);
  }

  onChangeSubject(elm) {
    this.setState({subjectValue: elm.target.value, subjectOK: elm.target.value.length >= 4}, this.checkButton);
  }

  onChangeEmail(elm) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.setState({
      emailValue: elm.target.value,
      emailOK: re.test(String(elm.target.value).toLowerCase())
    }, this.checkButton);
  }

  checkButton() {
    this.setState({disableSend: (this.state.nameOK === false || this.state.emailOK === false || this.state.textOK === false || this.state.subjectOK === false)});
  }

  render() {
    const form = this.renderForm();
    const year = new Date().getFullYear();
    return (
      <div id={"contact"}>
        <ToastContainer className='toast-container' />
        <div id={'ContactFormBackground'}>
          <Row>
            <Col sm={12} md={6}>
              <h3>Jsme připraveni k vypracování konkrétní nabídky pro řešení Vašeho požadavku</h3>
              <p className={"contact_form_info"}>Kontaktujte nás pro bezplatné zanalyzování Vaší zakázky a navržení efektivního řešení.</p>
            </Col>
            <Col><a className={'headsoft-button float-right'} href="mailto:info@headsoft.cz">Kontaktujte nás</a></Col>
          </Row>
          <Row>
            <Col>
              <h4>Napište nám</h4>
            </Col>
          </Row>
          <div ref={'contact_div'} id={'ContactForm'}>
            {form}
            <Row>
              <Col lg={4} md={5} className={'companyLocation bottom_box'}>
                <strong>Headsoft s.r.o.,</strong><br/>
                Staropramenná 530/12<br/>
                Praha 5<br/>
                150 00
              </Col>
              <Col lg={4} md={5} className={'saleContact bottom_box'}>
                <strong>Obchodní zastoupení</strong><br/>
                Tomáš Maloch<br/>
                + 420 773 302 799<br/>
                maloch@headsoft.cz
              </Col>
              <Col xs={4}>
              </Col>
            </Row>
            <div className={'copyWriter bottom-hs-text mt-5'}>
              <p>&copy; HEADSOFT {year}. Company registration No. 05451507.<br/>
                Made with <FontAwesomeIcon color={'#a2e2ff'} icon={['far', 'heart']}/> in Prague.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }


  renderForm() {
    return <div id={'FormBlock'}>
      <Row>
        <Col xs={12} md={6}>
          <Input name={"MessageName"}
                 onFocus={this.scrollToForm}
                 onBlur={this.focusOut}
                 onChange={this.onChangeName.bind(this)}
                 value={this.state.nameValue}
                 placeholder={this.strings.emailName} bsSize={'sm'}/>
        </Col>
        <Col xs={12} md={6}>
          <Input onFocus={this.scrollToForm}
                 onBlur={this.focusOut}
                 onChange={this.onChangeEmail.bind(this)}
                 value={this.state.emailValue}
                 placeholder={this.strings.emailEmail} bsSize={'sm'}/>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input onFocus={this.scrollToForm}
                 onBlur={this.focusOut}
                 onChange={this.onChangeSubject.bind(this)}
                 value={this.state.subjectValue}
                 placeholder={this.strings.emailSubject} bsSize={'sm'}/>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input onFocus={this.scrollToForm}
                 onBlur={this.focusOut}
                 onChange={this.onChangeText.bind(this)}
                 value={this.state.messageValue}
                 name="MessageText" type={'textarea'} placeholder={this.strings.emailMessage}
                 bsSize={'sm'}/>
        </Col>
      </Row>
      <Row>
        <Col>
          <Fade left when={this.state.buttonShow}>
            <Button size={'sm'} disabled={this.state.disableSend} ref={this.sendButton}
                    onClick={this.sendEmail} className={'float-right'}>{this.strings.sendEmail}</Button>
          </Fade>
        </Col>
      </Row>
    </div>
  }


  sendEmail() {
    let response = fetch('https://mail1.headsoft.cz/sendEmail.php', {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify({
        'client': 'HeadsoftMailApi',
        'from': 'info@headsoft.cz',
        'to': 'info@headsoft.cz',
        'subject': this.state.subjectValue,
        'reply-to': this.state.emailValue,
        'name': this.state.nameValue,
        'message': this.state.messageValue
      }),
    });
    let componente = this;
    response.then( (res) => {return res.json()}).then(data => {
      if (data === 'OK') {
        toast("Děkujeme za email. Brzy se Vám ozveme.", {type: toast.TYPE.SUCCESS});
        componente.setState({
          subjectValue: '',
          emailValue: '',
          nameValue: '',
          messageValue: ''
        })
      } else {
        toast(data, {type:toast.TYPE.ERROR});
      }
      }
    );
  }

}