import React, {Component} from 'react';
import {Col, Row} from "reactstrap";

const logo = require('../../images/torlin_logo.svg');

const images = [
  require('../../images/Torlin/footfall2.png'),
  require('../../images/Torlin/graphs.png'),
  require('../../images/Torlin/heatmap_viewer.png'),
];

export default class Torlin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image_width: 200,
    }
  }


  render() {
    return (
      <div id={'torlin_background'}>
        <div id={'torlin_block'}>
          <div className={'torlin_block_header'}>
            <div><img src={logo}/></div>
            <p>Nabízíme dohledový systém, který umožňuje<br/>
              podrobnou analýzu na sledovaném prostoru. .</p>
          </div>
        </div>

        <div id={'images-gallery'}>
          <Row>
            <Col className={'align-self-center'}><img src={images[0]}/></Col>
            <Col className={'align-self-center'}><img src={images[1]}/></Col>
            <Col className={'align-self-center'}><img src={images[2]}/></Col>
          </Row>
        </div>

        <div className={'description'}>
          <p><span className={'strong'}>TORLIN</span> je inteligentní kamerový systém, který disponuje širokou škálou možností výstupních hodnot s mnoha možnostmi využití jako například řízení průmyslové výroby, dohledové systémy nebo analýza sledovaného prostoru.
          S ohledem na náš tým programátorů je možné získat ze systému libovolné výstupní data dle požadavků zákazníka.</p>
        </div>
      </div>
    );
  }
}
