import React,{Component} from 'react';
import {Col, Row} from "reactstrap";

const logo_adfors = require('../../images/logos/adfors_logo.png');
const logo_orion = require('../../images/logos/orion_logo.png');
const logo_huatech = require('../../images/logos/huatech_logo.png');
const logo_orion_trade = require('../../images/logos/orion_trade_logo.png');
const logo_jestar = require('../../images/logos/jestar_logo.jpg');

export default class References extends Component {
  render() {
    return(
      <div id={'references'} className={'defaultBox'}>
        <div className={'header'}>
          <div className={'divider'}/>
          <h2>Reference</h2>
          <p className={'description'}>Výběr z klientů, se kterými<br/>
            spolupracujeme na projektech.</p>
        </div>
        <div className={'items'}>
          <Row>
            <Col>

              {/*<div className={'reference_block'}>*/}
                {/*<div className={'logo'}><img src={logo_adfors} /></div>*/}
                {/*<div className={'anounce'}>Projekt</div>*/}
                {/*<div className={'description'}>Kontrolní a řídící systém pro výrobní linky s textilní tkaninou.</div>*/}
              {/*</div>*/}

              <div className={'reference_block'}>
                <div className={'logo'}><img src={logo_jestar} /></div>
                <div className={'anounce'}>Projekt</div>
                <div className={'description'}>Dohledový systém se záznamem pro maloobchodní prodejnu domácích potřeb společnosti JESTAR s.r.o. – OD LÁDVÍ Praha.</div>
                <div className={'client_text'}>
                  <div className={'text'}>S ohledem na dispozici a skryté části prodejny jsme hledali partnera pro dodávku kamerového systému se záznamem, abychom prodejnu patřičně zabezpečili. Firma Headsoft nám navrhla ideální řešení, které jsme s potěšením přijali. S kamerovým systémem TORLIN jsme velmi spokojení. Splňuje náš požadavek na potřebný dohled nad fungováním prodejny a její ochranou.</div>
                  <div className={'name'}>Irena Jechová, jednatel JESTAR s.r.o.</div>
                </div>
              </div>

              <div className={'reference_block'}>
                <div className={'logo'}><img src={logo_orion} /></div>
                <div className={'anounce'}>Projekty</div>
                <div className={'description'}>Dohledový a footfall systém pro maloobchod pro více
                  jak 30 prodejen v ČR.<br/><br/>Správa POS Pohoda pro více jak 85 prodejen.<br/><br/>vývoj a správa objednávkového a reklamačního systému RIGEL pro více jak 115 prodejen.</div>
                <div className={'client_text'}>
                  <div className={'text'}>Velkoobchod ORION, spol. s r.o. využívá na svých prodejnách od firmy Headsoft kamerový systém a program RIGEL.  Vzhledem k dlouhodobé spolupráci a tudíž velké znalosti problematiky firmy Orion jsou služby prováděny v profesionální kvalitě. Program RIGEL je vytvářen dle našich potřeb a požadavků.</div>
                  <div className={'name'}>Jaroslava Králová, Výkonná ředitelka</div>
                </div>
              </div>
            </Col>
            <Col>

              <div className={'reference_block'}>
                <div className={'logo'}><img src={logo_huatech} /></div>
                <div className={'anounce'}>Projekt</div>
                <div className={'description'}>Kompletní realizace CRM systému na míru, přesně splňující podmínky interních procesů.</div>
                <div className={'client_text'}>
                  <div className={'text'}>Dodávka a pomoc při návrhu interního systému pro řízení obchodních procesů. Dodávka nezahrnovala pouze programování systému, ale také konzultace při návrhu a designu interních procesů firmy.</div>
                  <div className={'name'}>Tomáš Zloch, Chief Technology Officer</div>
                </div>
              </div>

              <div className={'reference_block'}>
                <div className={'logo'}><img src={logo_orion_trade} /></div>
                <div className={'anounce'}>Projekty</div>
                <div className={'description'}>Dohledový a footfall systém TORLIN instalovaný na více
                  jak 10 prodejen v SR.<br/><br/>Správa POS Pohoda pro více jak 25 prodejen.<br/><br/>Vývoj a správa objednávkového a reklamačního systému RIGEL pro více jak 25 prodejen.</div>
                <div className={'client_text'}>
                  <div className={'text'}>Díky společnosti Headsoft jsme nalezli spolehlivého partnera pro komplexní správu pokladního a vývoj interního systému. Firma nám dodává také kamerový a statistický nástroj Torlin pro naši maloobchodní síť prodejen. Jsme velmi spokojení s přístupem, firma Headsoft na případné dotazy ihned reaguje a těšíme se na další spolupráci při naší expanzi.</div>
                  <div className={'name'}>Erika Procházková, Výkonný riaditeľ</div>
                </div>
              </div>

            </Col>
          </Row>
        </div>
      </div>
    );
  }
}