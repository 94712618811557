import React, {Component} from 'react'
import {Col, Row} from "reactstrap";

const crm = require('../../images/ic_crm.svg');
const develop = require('../../images/ic_apps.svg');
const pos = require('../../images/ic_cashmachine.svg');

export default class Projects extends Component {
  render() {
    return (
      <div id={'products'} className={'defaultBox'}>
        <div className={'header'}>
          <div className={'divider'}/>
          <h2>Mimo náš stěžejní produkt<br/>
            nabízíme také další služby.</h2>
        </div>
        <div className={'items'}>
          <Row>
            <Col>
              <div className={'box'}>
                <div className={'image'}> <img src={crm} /> </div>
                <h3> Vývoj CRM </h3>
                <p>Vyvíjíme plně konfigurovatelné CRM, které je možné nasadit na jakýkoliv procesní systém. Potřebujete dát svému workflow řád? Naše CRM Vám v tom pomůže!</p>
              </div>
            </Col>
            <Col>
              <div className={'box'}>
                <div className={'image'}> <img src={develop} /> </div>
                <h3> Vývoj aplikací na míru </h3>
                <p>Potřebujete aplikaci šitou na míru? Máte specifické zadání, ale žádné dostupné řešení Vám nevyhovuje? Oslovte nás a společně nalezneme správnou cestu a vyvineme vhodné řešení na míru.</p>
              </div>
            </Col>
            <Col>
              <div className={'box'}>
                <div className={'image'}> <img align="bottom" src={pos} /> </div>
                <h3> Pokladní systémy </h3>
                <p>Máte několik provozoven a chcete změnit pokladní systém, který bude více vyhovat vašim potřebám? Chcete začít prodávat v malém obchůdku s minimálními vstupními náklady? Máme pro Vás vhodné řešení!</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}